import revive_payload_client_OoESQDTYFU from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_qqc2yal36firc63galdke3b42i/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_xlcB5s31zG from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_qqc2yal36firc63galdke3b42i/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_bHfOgZSFKZ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_qqc2yal36firc63galdke3b42i/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_c4FAIpuIbS from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.32.0_vite@5.4.14_@types+node@22.10.10_sass@1.5_eo27z3ju37vgp6vj36er56zhhu/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_u7Jxk9rUm4 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_qqc2yal36firc63galdke3b42i/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_uppGayESCZ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_qqc2yal36firc63galdke3b42i/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_r2c9inDAhm from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_qqc2yal36firc63galdke3b42i/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_25dfaQ5n4d from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_qqc2yal36firc63galdke3b42i/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_4ZxH31EjIl from "/opt/buildhome/repo/node_modules/.cache/nuxt/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_zlvi6dcIsi from "/opt/buildhome/repo/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_4ax7DjMdc0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_qqc2yal36firc63galdke3b42i/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_2MzJskYA2v from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.32.0_vite@5.4.14_@types+node@22.10.10_sass@1.55.0_t_chekf5arbhp656yitit3zcmpvu/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import preview_client_7svsuGTU5u from "/opt/buildhome/repo/node_modules/.pnpm/@nuxthq+studio@2.2.1_magicast@0.3.5_rollup@4.32.0/node_modules/@nuxthq/studio/dist/runtime/plugins/preview.client.js";
import siteConfig_oimG8JKJkH from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.32.0_vite@5.4.14_@types+node@22.10.10_sass@1.55._xsyxqbpit6zgciadcz3nceq4ji/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_b8giOJERl0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.32.0_vite@5.4.14_@types+node@22.10.10_sass@1.55._xsyxqbpit6zgciadcz3nceq4ji/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_KMuJad7rtv from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.32.0_vite@5.4.14_@types+node@22.10.10_sass@1.55._xsyxqbpit6zgciadcz3nceq4ji/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import defaults_k4fxPZmOYs from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.32.0_vite@5.4.14_@types+node@22.10.10_sass@1.55._xsyxqbpit6zgciadcz3nceq4ji/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  revive_payload_client_OoESQDTYFU,
  unhead_xlcB5s31zG,
  router_bHfOgZSFKZ,
  _0_siteConfig_c4FAIpuIbS,
  payload_client_u7Jxk9rUm4,
  navigation_repaint_client_uppGayESCZ,
  check_outdated_build_client_r2c9inDAhm,
  chunk_reload_client_25dfaQ5n4d,
  plugin_4ZxH31EjIl,
  components_plugin_zlvi6dcIsi,
  prefetch_client_4ax7DjMdc0,
  plugin_2MzJskYA2v,
  preview_client_7svsuGTU5u,
  siteConfig_oimG8JKJkH,
  inferSeoMetaPlugin_b8giOJERl0,
  titles_KMuJad7rtv,
  defaults_k4fxPZmOYs
]